import React from 'react'
import './Layout.scss'
import { Logotype } from '../../assets/svgs'

const Layout = ({ children }) => {
  return (
    <div className="main">
      <div className="header">
        <div className="logo">
          <a href="https://consupedia.se">
            <Logotype color="#fff" />
          </a>
        </div>
        <nav></nav>
      </div>
      {children}
      <section
        className="alignfull"
        style={{ backgroundColor: 'rgba(12,13,13,1)' }}
      >
        <div className="section-container">
          <div className="d-none d-lg-block">
            <div className="container">
              <div className="row">
                <div className="col-5">
                  <h3 style={{ width: '10rem' }}>
                    <span style={{ display: 'none' }}>Consupedia</span>
                    <Logotype color="#fff" />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Layout
