import * as React from 'react'

const Logotype = ({ color = '#0f181f' }) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 282.9 53.2">
      <g fill={color}>
        <path
          d="M80.3,26.5c0-7.3,5.7-12.9,12.5-12.9s12.5,5.7,12.5,12.9c0,7.3-5.7,12.9-12.5,12.9S80.3,33.7,80.3,26.5z
    M99.5,26.5c0-4.2-3-7.3-6.7-7.3s-6.7,3-6.7,7.3c0,4.2,3,7.3,6.7,7.3S99.5,30.7,99.5,26.5z"
        />
        <path d="M109,13.8h5.2l10.3,14.9V13.8h5.9v25.4h-5.2l-10.3-14.9v14.9H109V13.8z" />
        <path
          d="M134.2,31.1l5.7-0.4c0,2.2,1.2,3.4,3.6,3.4c2.2,0,3.4-1.2,3.4-2.6c0-3.8-12.5-1.6-12.5-10.7
   c0-3.8,3.4-7.3,9.1-7.3c5.5,0,8.9,3.4,8.9,8.1l-5.7,0.4c0-2-1.2-3.2-3.2-3.2c-1.8,0-3,1.2-3,2.4c0,3.6,12.5,1.4,12.5,10.7
   c0,4-3.4,7.5-9.5,7.5C137.6,39.4,134.2,36,134.2,31.1z"
        />
        <path
          d="M156.2,28.9V13.8h5.9v15.1c0,3,1.8,4.8,4.6,4.8s4.6-1.8,4.6-4.8V13.8h5.9v15.1c0,6.3-4.2,10.5-10.5,10.5
   S156.2,35.2,156.2,28.9z"
        />
        <path
          d="M181.8,13.8h9.9c5.2,0,9.1,3.8,9.1,8.7c0,4.8-3.8,8.7-9.1,8.7h-4v8.1h-5.9V13.8z M191.7,25.5
   c1.8,0,3.2-1.4,3.2-3c0-1.6-1.4-3-3.2-3h-4v6.1H191.7z"
        />
        <path d="M203.8,13.8H222v5.7h-12.3v4H221v5.7h-11.3v4.4h12.7v5.7h-18.6V13.8z" />
        <path
          d="M225.8,13.8h8.7c7.1,0,12.7,5.7,12.7,12.7c0,7.1-5.7,12.7-12.7,12.7h-8.7V13.8z M234.5,33.5
   c3.8,0,6.9-3,6.9-7.1c0-4-3-7.1-6.9-7.1h-2.8v14.1H234.5z"
        />
        <path d="M250,13.8h5.9v25.4H250V13.8z" />
        <path
          d="M267.7,13.8h6.1l9.1,25.4h-6.1l-1.6-4.8h-8.9l-1.6,4.8h-6.1L267.7,13.8z M273.6,29.3l-2.8-8.5l-2.8,8.5H273.6z
   "
        />
        <path
          d="M49.1,41.4l-10.4-9.1c0.7-1.5,1.2-3.2,1.3-4.9c0.2-3.5-1-7-3.3-9.6c-2.4-2.7-5.6-4.2-9.1-4.5
   c-7.3-0.5-13.6,5.1-14.1,12.5c-0.5,7.3,5.1,13.6,12.5,14.1c0.3,0,0.6,0,0.8,0c3.1,0,6-1.1,8.4-3l5.5,4.8c-4.1,3.8-9.6,5.8-15.2,5.4
   C14,46.5,5.3,36.7,6,25.3c0.3-5.5,2.8-10.5,6.9-14.2S22.4,5.7,27.9,6c6.3,0.4,12,3.6,15.6,8.8l4.9-3.4c-4.7-6.7-12-10.8-20.2-11.3
   C21.1-0.4,14.3,2,9,6.7C3.7,11.4,0.5,17.9,0.1,25C-0.9,39.6,10.3,52.2,25,53.1c0.6,0,1.1,0.1,1.7,0.1c7.6,0,14.8-3.2,19.9-9l0,0
   L49.1,41.4z M31.5,32.1c-1.5,1.3-3.3,1.9-5.3,1.8c-4-0.2-7.1-3.7-6.8-7.7c0.2-3.9,3.5-6.9,7.3-6.9c0.1,0,0.3,0,0.5,0
   c4,0.2,7.1,3.7,6.8,7.7C33.8,29,32.9,30.8,31.5,32.1z"
        />
        <path
          d="M73.8,30.5c-1.2,2-3.3,3.2-5.6,3.2c-3.7,0-6.8-3.1-6.8-7.4c0-4.3,3.1-7.4,6.8-7.4c2.3,0,4.4,1.2,5.6,3.2
   l4.6-3.6c-2.3-3.2-6.1-5.3-10.2-5.3c-7,0-12.7,5.7-12.7,13.1c0,7.4,5.7,13.1,12.7,13.1c4.2,0,7.9-2.1,10.2-5.3L73.8,30.5z"
        />
      </g>
    </svg>
  )
}

const Climate = ({ color = '#0f181f' }) => {
  return (
    <svg
      className="climate-icon"
      x="0px"
      y="0px"
      width="29.6px"
      height="16.9px"
      viewBox="0 0 29.6 16.9"
    >
      <g fill={color}>
        <path
          d="M24.6,7c-0.2,0-0.5,0-0.7,0.1c0-0.1,0-0.1,0-0.2c0-2.1-1.7-3.8-3.8-3.8c-0.7,0-1.4,0.2-2,0.6
        c-1.2-2.1-3.4-3.6-6-3.6C8.4,0,5.3,3,5.3,6.8c0,0.1,0,0.1,0,0.2C5.2,7,5.1,7,5,7c-2.8,0-5,2.2-5,5c0,2.6,2,4.8,4.6,5l0,0h20
        c2.8,0,5-2.2,5-5S27.3,7,24.6,7z"
        />
      </g>
    </svg>
  )
}
const Water = ({ color = '#0f181f' }) => {
  return (
    <svg
      className="water-icon"
      x="0px"
      y="0px"
      width="16.5px"
      height="28px"
      viewBox="0 0 16.5 28"
    >
      <g fill={color}>
        <path d="M16.5,19.7c0,4.6-3.7,8.3-8.3,8.3S0,24.3,0,19.7S7.9,0,8.3,0C8.6,0,16.5,15.2,16.5,19.7z" />
      </g>
    </svg>
  )
}
const Fairness = ({ color = '#0f181f' }) => {
  return (
    <svg
      className="fairness-icon"
      x="0px"
      y="0px"
      width="28.4px"
      height="24px"
      viewBox="0 0 28.4 24"
    >
      <path
        fill={color}
        d="M26.2,7.1c-2.3-4.9-2.6-4.9-3.2-4.9c-0.1,0-0.1,0-0.2,0v0h-7.5V1.1c0-0.6-0.5-1.1-1.1-1.1
      c-0.6,0-1.1,0.5-1.1,1.1v1.1H5.6v0c-0.1,0-0.1,0-0.2,0c-0.6,0-1,0-3.2,4.9C0.7,10.3,0,12.5,0,13.7c0,3,2.4,5.4,5.4,5.4
      s5.4-2.4,5.4-5.4c0-1.2-0.7-3.4-2.2-6.5c-0.5-1.2-1-2.1-1.3-2.7h5.8v17.5H8.9c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h10.6
      c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-4.2V4.4h5.8c-0.4,0.7-0.8,1.6-1.3,2.7c-1.5,3.1-2.2,5.3-2.2,6.5c0,3,2.4,5.4,5.4,5.4
      c3,0,5.4-2.4,5.4-5.4C28.4,12.5,27.7,10.3,26.2,7.1z M8.7,13.7H2.2c0-1.4,2-5.8,3.2-8.2C6.6,7.8,8.7,12.2,8.7,13.7z M23,5.5
      c1.2,2.4,3.2,6.8,3.2,8.2h-6.5C19.7,12.2,21.8,7.8,23,5.5z"
      />
    </svg>
  )
}
const Health = ({ color = '#0f181f' }) => {
  return (
    <svg
      className="health-icon"
      x="0px"
      y="0px"
      width="26.7px"
      height="23px"
      viewBox="0 0 26.7 23"
    >
      <g fill={color}>
        <path
          d="M13.4,23c-0.8-0.6-8.6-6.4-11-9.3C1.1,12,0.2,10.2,0,8.1c-0.2-2.5,0.7-4.6,2.6-6.2c2.8-2.4,6.9-2.5,9.7-0.1
        c0.4,0.3,0.7,0.6,1,0.9c0.3-0.3,0.6-0.6,1-0.9c2.8-2.4,7-2.3,9.7,0.1c1.9,1.6,2.8,3.8,2.6,6.2c-0.2,2.1-1.1,3.9-2.4,5.5
        C22,16.6,14.2,22.4,13.4,23z"
        />
      </g>
    </svg>
  )
}
export { Logotype, Climate, Water, Fairness, Health }
