import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import Layout from '../components/Layout/Layout'

function htmlDecode(html) {
  return html.replace(/&([a-z]+);/gi, (match, entity) => {
    const entities = {
      amp: '&',
      apos: "'",
      gt: '>',
      lt: '<',
      nbsp: '\xa0',
      quot: '"'
    }
    entity = entity.toLowerCase()
    if (entities.hasOwnProperty(entity)) {
      return entities[entity]
    }
    return match
  })
}
const makeCode = (gtin, list = '') => {
  return `<div class="consu-widget" data-gtin='${gtin}'${
    list !== '' ? ` list="${list}"` : ''
  }></div><script>var s=document.createElement('script');s.setAttribute('src','https://static.consupedia.com/index.js?v=2.0.12');s.onload=function(){window.consupedia()};document.body.appendChild(s);</script><link rel="stylesheet" href="https://static.consupedia.com/index.css?v=2.0.12">`
}
const defaultCode = '7318690081883'
// markup
const IndexPage = () => {
  const scriptCode = `<link rel="stylesheet" href="https://static.consupedia.com/index.css?v=2.0.12">`
  const [demotin, setDemotin] = useState(defaultCode)
  const [code, setCode] = useState('')
  const [climate, setClimate] = useState(false)
  const [fairness, setFairness] = useState(false)
  const [health, setHealth] = useState(false)
  const [water, setWater] = useState(false)

  useLayoutEffect(() => {
    var s = document.createElement('script')
    s.setAttribute('src', 'https://static.consupedia.com/index.js?v=2.0.12')
    s.onload = () => window.consupedia()
    document.body.appendChild(s)
    setCode(makeCode(defaultCode))
  }, [])

  useEffect(() => {
    setCode(makeCode(demotin, list))
    window.consupedia && window.consupedia()
  }, [demotin, climate, fairness, health, water])

  const list = [
    climate ? 'climate' : '-',
    fairness ? 'fairness' : '-',
    health ? 'health' : '-',
    water ? 'water' : '-'
  ]
    .filter((a) => a !== '-')
    .join(',')

  return (
    <Layout>
      <title>Consupedia widget</title>
      <div className="hero dark-bg alignfull">
        <div className="content-container">
          <div>
            <h1>Consupedia product widget</h1>
            <br />
            {/* <div className="consu-widget" data-gtin="7314660002155"></div> */}
            {/* <h4>
              Vi har världens största databas för hållbarhetsinformation om
              livsmedel.
            </h4> */}
          </div>
        </div>
      </div>
      <section className="alignfull">
        <div className="section-container">
          <div>
            <h2>Visa Consupedias hållbarhetsdata på din webbsida</h2>
            <p>
              Vi på Consupedia har byggt världens största databas för
              hållbarhetsinformation om livsmedel tillsammans med KTH och
              Högskolan Dalarna. Nu kan du använda den för att informera dina
              kunder och besökare om dina produkters hållbarhet.
            </p>
            <p>
              <strong>Var med och gör världen bättre, du också.</strong>
            </p>
          </div>
        </div>
      </section>
      <section className="alignfull green-bg">
        <div className="section-container">
          <div className="single">
            <h2>Skapa din widget</h2>
          </div>
          <div className="cols">
            <div className="col">
              <div className="demo-form">
                <div>
                  <strong>Skriv in din Ean-kod: </strong>
                  <input
                    type="text"
                    defaultValue={defaultCode}
                    onChange={(e) =>
                      e.target.value > 1000 ? setDemotin(e.target.value) : null
                    }
                  />
                </div>
                <ul className="consupedia-list">
                  <strong>Visa endast: &nbsp;</strong>
                  <li>
                    <input
                      type="checkbox"
                      checked={climate}
                      onChange={() => setClimate(!climate)}
                    ></input>{' '}
                    Klimat
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={health}
                      onChange={() => setHealth(!health)}
                    ></input>{' '}
                    Hälsa
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={water}
                      onChange={() => setWater(!water)}
                    ></input>{' '}
                    Vatten
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={fairness}
                      onChange={() => setFairness(!fairness)}
                    ></input>{' '}
                    Rättvisa
                  </li>
                </ul>
                <div>
                  <br />
                  <p>
                    Kopiera och klistra in nedan kod där du vill att din widget
                    ska vara placerad:
                  </p>
                  <textarea value={code} readOnly></textarea>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <div className="demo-form">
                <div>
                  <strong>Använd flera widgetar på samma sida</strong>
                  <p>
                    Lägg till flera widgetar på en sida genom att placera nedan
                    kod innan {`</body>`} -taggen:
                  </p>
                  <textarea
                    value={`<script>var s = document.createElement('script');s.setAttribute('src','https://static.consupedia.com/index.js?v=2.0.12');s.onload = function(){window.consupedia()};document.body.appendChild(s);</script><link rel="stylesheet" href="https://static.consupedia.com/index.css?v=2.0.12">`}
                    readOnly
                  ></textarea>
                  <p>
                    Lägg sedan in nedan tagg på sidan där du vill att en widget
                    ska visas:
                  </p>
                  <code>{`<div class="consu-widget" data-gtin='{DIN-EAN-KOD}'></div>`}</code>
                  <p>
                    För att endast visa valda värden, lägg till nedan
                    data-attribut:
                  </p>
                  <code>{`<div class="consu-widget" data-gtin='{DIN-EAN-KOD}' data-list='{värdelista}'></div>`}</code>
                  <p>Exempel:</p>
                  <code>{`<div class="consu-widget" data-gtin='7318690081883' data-list='climate,health'></div>`}</code>
                </div>
              </div>
              <div className="demo-form">
                <div>
                  <strong>
                    Visa en sammanställning av flera produkter eller
                    ingredienser
                  </strong>
                  <p>Placera nedan kod innan {`</body>`} -taggen:</p>
                  <textarea
                    value={`<script>var s = document.createElement('script');s.setAttribute('src','https://static.consupedia.com/index.js?v=2.0.12');s.onload = function(){window.consupedia()};document.body.appendChild(s);</script><link rel="stylesheet" href="https://static.consupedia.com/index.css?v=2.0.12">`}
                    readOnly
                  ></textarea>
                  <p>
                    Lägg till nedan tagg på sidan där du vill att en widget ska
                    visas:
                  </p>
                  <code>{`<div class="consu-widget" data-content='{DITT-INNEHÅLLS-OBJEKT}'></div>`}</code>
                  <p>
                    Data-attributet (data-content) förväntar sig ett JSON objekt
                    som sträng enligt nedan:
                  </p>
                  <p>Exempel EAN-objekt:</p>
                  <code>
                    '
                    {JSON.stringify([
                      {
                        gtin: '7318690081883',
                        grams: '34'
                      },
                      {
                        gtin: '7340083453083',
                        grams: '64.6'
                      }
                    ])}
                    '
                  </code>
                  <p>Exempel ingrediens-objekt:</p>
                  <code>
                    '
                    {JSON.stringify([
                      {
                        ingredient: 'Socker',
                        grams: '5'
                      },
                      {
                        ingredient: 'Mjöl',
                        grams: '200.0'
                      }
                    ])}
                    '
                  </code>
                  <p>
                    För att endast visa valda värden, lägg till nedan
                    data-attribut (data-list):
                  </p>
                  <code>{`<div class="consu-widget" data-content='{DITT-EAN-OBJEKT}' data-list='{värdelista}'></div>`}</code>
                  <p>Exempel:</p>
                  <code>{`<div class="consu-widget" data-content='[{"gtin":"7318690081883","grams":"34"},{"gtin":"7340083453083","grams":"64.6"}]' data-list='climate,health'></div>`}</code>
                </div>
              </div>
            </div>
            <div className="col">
              <div
                className="consu-widget"
                data-gtin={demotin}
                data-list={list}
              ></div>
              <div dangerouslySetInnerHTML={{ __html: scriptCode }}></div>
            </div>
          </div>
        </div>
      </section>
      <section className="alignfull">
        <div className="section-container">
          <div>
            <h2>Support</h2>
            <p>
              Behöver du hjälp att implementera widgeten på din webbsida?
              Kontakta oss:
            </p>
            <p>
              <strong>
                <a href="mailto:info@consupedia.com">info@consupedia.com</a>
              </strong>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
